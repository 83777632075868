@import './themes';

@mixin nb-overrides() {
  nb-sidebar {
    // background-color: transparent;
    main-container-fixed {
      top: 0%;
    }
    // li:hover{
    //   background-color: rgb(179, 227, 255);
    //   color: white;
    //   background-blend-mode: lighten;
    // }
  }
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
      font-size: 2.5rem !important;
    }
  }
}
